import { useMutation, useQuery } from '@tanstack/react-query'

import {
  deleteData,
  getData,
  patchData,
  patchFormData,
  postData,
  postFormData,
  updateData,
} from '../../apis/api.helpers'
import apiUrl from '../../apis/api.url'
import { QueryParams } from '../../common/types'
import { useSnackbarManager } from '../../components/common/snackbar'
import { getErrorMessage, parseQueryParams } from '../../utilities/parsers'

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}

interface Params {
  [key: string]: string | number | boolean
}

const fetchOrderData = async (input: QueryParams) => {
  const url = buildUrlWithParams(apiUrl.ORDER_SERVICES_URL, {
    ...input,
  })
  const response = await getData(url)
  return response?.data
}

export const useByService = (input: QueryParams) => {
  return useQuery(['by_service', input], () => fetchOrderData(input), {
    refetchOnWindowFocus: false,
    //staleTime: 50000,
  })
}
export const deleteService = (orderId?: string, serviceId?: string) => {
  return deleteData(
    `${apiUrl.ORDER_LIST_URL}${orderId}/remove-item/${serviceId}`
  )
}
export const cancelService = (serviceId?: string) => {
  return patchData(`${apiUrl.ORDER_SERVICES_URL}/${serviceId}/cancel`, {})
}
export const restoreService = (serviceId?: string) => {
  return patchData(`${apiUrl.ORDER_SERVICES_URL}/${serviceId}/restore`, {})
}
export const getServiceDetails = (id: any) => {
  return getData(`${apiUrl.SERVICE_DETAIL_URL}/${id}`)
}
export const getAllServiceList = (params: Params) => {
  return getData(buildUrlWithParams(apiUrl.SERVICE_LIST_URL + 'list/', params))
}
export const { getServiceCategoryList, getTeams, getOrderServiceStatusList } = {
  getServiceCategoryList: (data: any) =>
    getData(buildUrlWithParams(apiUrl.SERVICE_CATEGORY, data)),
  getTeams: (params: Params) =>
    getData(buildUrlWithParams(apiUrl.VENDORS + 'all-vendor/', params)),
  getOrderServiceStatusList: (params: Params) =>
    getData(buildUrlWithParams(apiUrl.SERVICE_STATUS, params)),
}

const getServicePaymentsDetails = async (id: string, input: QueryParams) => {
  const url = buildUrlWithParams(
    `${apiUrl.SERVICE_ALLOCATED}?order_item_id=${id}`,
    {
      ...input,
    }
  )
  const response = await getData(url)
  return response?.data
}

export const useServicePaymentsDetails = (id: string, input: any) => {
  return useQuery(
    ['service_payments_list', id, input],
    () => getServicePaymentsDetails(id, input),
    {
      refetchOnWindowFocus: false,
      //staleTime: 50000,
    }
  )
}

const getServicePaymentRequestDetails = async (input: QueryParams) => {
  const url = buildUrlWithParams(`${apiUrl.SERVICE_PAYMENT_APPROVAL}`, {
    ...input,
  })
  const response = await getData(url)
  return response
}

export const useServicePaymentRequestDetails = (input: any) => {
  return useQuery(
    ['service_payments_request_list', input],
    () => getServicePaymentRequestDetails(input),
    {
      refetchOnWindowFocus: false,
      //staleTime: 50000,
    }
  )
}

export const serviceUpdateTeamLead = (
  orderid: string,
  serviceid: string,
  data: any
) => {
  return postData(
    `${apiUrl.ORDER_LIST_URL}${orderid}/items/${serviceid}/assign-team
`,
    data
  )
}
export const serviceunAllocateTeamLead = (
  orderid: string,
  serviceid: string
) => {
  return updateData(
    `${apiUrl.ORDER_LIST_URL}${orderid}/items/${serviceid}/unassign-team`,
    {}
  )
}
export const activityReArrange = (
  orderid: string,
  serviceid: string,
  task_id: any,
  data: any
) => {
  return patchData(
    `${apiUrl.ORDER_LIST_URL}${orderid}/items/${serviceid}/tasks/${task_id}/rearrange`,
    data
  )
}

export const getServiceDetailsSummary = (id: any) => {
  return getData(`${apiUrl.SERVICE_ALLOCATED}${id}`)
}

export const unAllocatePayment = (data: any) => {
  return postData(`${apiUrl.SERVICE_UN_ALLOCATE}`, data)
}

//TARGET CLOSE DATE

export const updateTargetCloseDate = (
  input: any,
  order_id: any,
  service_id: any
) => {
  return postData(
    `${apiUrl.ORDER_LIST_URL}${order_id}/items/${service_id}/target-close-date`,
    input
  )
}

export const getServiceActivityDetails = async (
  orderid: string,
  serviceid: string,
  input?: QueryParams
) => {
  const url = buildUrlWithParams(
    `${apiUrl.ORDER_LIST_URL}${orderid}/items/${serviceid}/tasks`,
    {
      ...input,
    }
  )
  const response = await getData(url)
  return response?.data
}

export const addService = (data: { input: any; id: string }) => {
  return postData(`${apiUrl.ORDER_LIST_URL}${data?.id}/add-items`, data?.input)
}

export const useAddService = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(addService, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Service Created successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error.response.data.errors[0].message[0] ?? error.response.data.errors
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}

export const {
  getStakeholder,
  getActivityList,
  getCollectibles,
  getDeliverables,
} = {
  getStakeholder: (data: any) =>
    getData(buildUrlWithParams(apiUrl.STAKEHOLDER, data)),
  getActivityList: (params: Params) =>
    getData(buildUrlWithParams(apiUrl.ACTIVITY_LIST, params)),
  getCollectibles: (params: Params) =>
    getData(buildUrlWithParams(apiUrl.COLLECTIBLES, params)),
  getDeliverables: (params: Params) =>
    getData(buildUrlWithParams(apiUrl.DELIVERABLES, params)),
}

export const addActivity = (data: {
  input: any
  serviceId: string
  orderId: string
}) => {
  return postData(
    `${apiUrl.ORDER_LIST_URL}${data?.orderId}/items/${data?.serviceId}/tasks`,
    data?.input
  )
}

export const useAddActivity = (
  handleSubmission: (input: any, serviceId: string, orderId: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(addActivity, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.serviceId, res.orderId)
      enqueueSnackbar('Activity Created successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error.response.data.error.message ??
            error.response.data.error ??
            error.response.data.error.message
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}

export const delete_activity = (taskId?: string) => {
  return deleteData(`${apiUrl.ORDER_LIST_URL}all-tasks/${taskId}`)
}

export const assignEmployeeToActivity = (
  orderId: string,
  service_id: string,
  task_id: string,
  input: any
) => {
  return postData(
    `${apiUrl.ORDER_LIST_URL}${orderId}/items/${service_id}/task/${task_id}`,
    input
  )
}

export const serviceStatusList = (id: string, data?: any) => {
  return getData(`${apiUrl.SERVICE_STATUS_URL}${id}${data}`)
}
export const serviceChangeStatus = (
  orderId: string,
  serviceId: string,
  data: any
) => {
  return patchFormData(
    `${apiUrl.ORDER_LIST_URL}${orderId}/items/${serviceId}/change_status`,
    data
  )
}

export const getOrderStatusList = (params: Params) => {
  return getData(buildUrlWithParams(apiUrl.ORDER_STATUS, params))
}

export const getAssigneeList = (params: Params) =>
  getData(
    `${apiUrl.EMPLOYEE_LIST_URL}service-assignee/${parseQueryParams(params)}`
  )
export const getFormBuilder = (input: any) => {
  return getData(`${apiUrl.ORDER_LIST_URL}${input.id}/meta-data`)
}
export const postFormBuilder = (input: any) => {
  return postFormData(
    `${apiUrl.ORDER_LIST_URL}${input.id}/meta-data`,
    input.data
  )
}

export const updateFormBuilder = (input: any) => {
  return postFormData(
    `${apiUrl.SERVICE_LIST_URL}${input.id}/service-form-layout`,
    input.data
  )
}
export const shareDataCollection = (id: string, data?: any) => {
  return patchData(
    `${apiUrl.ORDER_SERVICES_URL}/${id}/share_data_collection_form`,
    data
  )
}
